/* eslint-disable */
<template>
  <div class="setting_main">
    <div
      id="setting_main_2"
      class="block"
    >
      <div class="block_content">
        <b-form-group
          label="External url"
          label-for="externalUrl"
          description="This is OS-CL external URL. This is needed to allow terraform backend support."
        >
          <b-form-input
            id="externalUrl"
            v-model="settings.externalUrl"
          />
        </b-form-group>

        <div class="heading1">
          <h2 class="heading">
            Environment Variables
            <b-button
              variant="success"
              @click="addVariable()"
            >
              +
            </b-button>
          </h2>
          <small>This is the environment variables which are fed to the runner.</small>
        </div>
        <app-settings-variable
          v-for="(envVar, index) in settings.envVars"
          :id="index"
          :key="index"
          :variable="envVar"
          @delete="removeVariable(index)"
        />

        <b-button
          variant=""
          class="set_btn"
          @click="save()"
        >
          <!-- <font-awesome-icon icon="save" /> -->
          Save
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
  import AppSettingsVariable from '@/pages/settings/settings-variable.vue';
  import {
    getSettings,
    saveSettings,
  } from '@/shared/api/settings-api';
  import { displayNotification } from '@/shared/services/modal-service';

  export default {
    name: 'AppSettings',
    components: { AppSettingsVariable },
    data: () => ({
      settings: { externalUrl: '', envVars: [] },
    }),
    async created() {
      this.settings = await getSettings();
    },
    methods: {
      addVariable() {
        this.settings.envVars.push({});
      },
      removeVariable(index) {
        this.settings.envVars.splice(index, 1);
      },
      save() {
        saveSettings(this.settings)
          .then(() => displayNotification(this, { message: 'Settings saved', variant: 'success' }))
          .catch(({ error, message }) => displayNotification(this, { title: error, message, variant: 'danger' }));
      },
    },
  };
</script>

<style scoped>

.set_btn {
  background-color: #FF9900;
  outline: none;
  border: none;
  border-radius: 40px;
  padding: 10px 20px;
}
.heading {
  color: Black;
}
#setting_main_2{
  width: 80%;
  margin: auto;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #dadce0;
}
@media only screen and (max-width: 480px) {
  #setting_main_2 {
    width: 100%;
    box-shadow: none;
    border: none;
    }
    .setting_main{
      background-image: none;
    }

  }
  @media only screen and (max-width: 768px) {
    #setting_main_2 {
    width: 100%;
    box-shadow: none;
    border: none;
    }
    .setting_main{
      background-image: none;
    }

  }
</style>
